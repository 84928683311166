@import '/src/styles/functions.scss';
@import '/src/styles/mixins.scss';

.mainContainer {
    position: relative;
    &::before {
        content: "";
        position: fixed;
        background-image: image-set(var(--background-image), var(--background-image-fallback));
        background-size: cover;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -9999;
    }
}

.main {
    border-radius: 0 0 50px 50px;
    max-width: var(--container-width);
    width: var(--container-vw-width);
    margin: 0 auto;
    @include gridDown(sm) {
        padding-top: calc( var(--frontpage-mobile-navigation-height) + 4rem );
    }
}

/* .mobileNavigation {
    display: none;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    background-color: #F8F8F6;
    height: var(--frontpage-mobile-navigation-height);
    padding: 1rem #{clamp_helper(10, 140)} 0;
    border-radius: 0 0 3rem 3rem;
    @include gridDown(sm) {
        display: block;
    }
} */

.mobileContent {
    display: none;
    @include gridDown(md) {
        display: block; 
    }
}

.desktopContent {
    @include gridDown(md) {
        display: none;
    }
}

.header {
    background-color: #f8f8f6c7;
    border-radius:  0 0 50px 50px;
    padding: 0 #{clamp_helper(10, 140)} var(--page-padding-y); 
    max-width: var(--container-width);
    
    margin: 0 auto var(--spacing-12);
    @include gridDown(md) {
        margin-bottom: 0;
    }
    @include gridDown(sm) {
        padding-left: 0;
        padding-right: 0;
    }
    h1 {
        margin-bottom: var(--spacing-4);
        font-weight: 300;
        text-align: center;
    }
    &__logo {
        padding: var(--spacing-8) 0;
        text-align: center;
        @include gridDown(sm) {
            display: none;
        }
    }
    &__columns {
        display: flex;
        flex-wrap: wrap;
        --gap: 3rem;
        gap: var(--gap);
        @include gridDown(lg) {
            flex-direction: column;
        }
    }

    &__column {
        width: calc( 50% - var(--gap) / 2 );
        background-color: #FFF;
        border-radius: 50px;
        overflow: hidden;
        --header-column-padding: #{clamp_helper(45, 50)} #{clamp_helper(20, 50)};

        @include gridDown(lg) {
            width: 100%;
        }
        @include gridDown(sm) {
            border-radius: 25px;
        }

        &--kysely {
            width: 100%;
        }
        
    }

    &__columnInner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: var(--header-column-padding);
        h4, h5 {
            display: flex;
            flex-wrap: wrap;
            gap: 0.75em;
            align-items: flex-end;
            margin-bottom: var(--spacing-3);
            svg {
                width: #{clamp_helper(35, 63.338)};
                height: auto;
            }
        }

        p {
            font-size: clamp_helper(17, 21);
            font-weight: 300;
            margin-bottom: var(--spacing-2);
        }

        &--kysely {
            background-color: var(--color-blue);
            color: #FFF;
            @include gridUp(lg) {
                flex-direction: row;
                align-items: center;
                gap: 4rem;
                > div {
                    &:first-child {
                        display: flex;
                        align-items: center;
                        gap: 4rem;
                        h5 {
                            flex-direction: row;
                            flex-wrap: nowrap;
                        }
                    }
                    &:last-child {
                        button {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }

    &__desktopButton {
        text-align: right;
        @include gridDown(md) {
            display: none; 
        }
    }

    &__mobileButton {
        display: none;
        text-align: right;
        @include gridDown(md) {
            display: block;
        }
    }

    @include gridDown(sm) {
        background-color: transparent;
    }
}

.section {
    padding-bottom: var(--spacing-12);
    @include gridDown(md) {
        padding-bottom: 0;
    }
    &__columns {
        display: flex;
        border-radius: 50px;
        overflow: hidden;
        @include gridDown(md) {
            flex-wrap: wrap;
            border-radius: 0;
        }
    }
    &__column {
        
        width: 50%;
        padding: #{clamp_helper(50, 100)};
        background-color: #FFF;
        @include gridDown(md) {
            width: 100%; 
            padding: var(--header-column-padding);
            h2 {
                font-size: 2.8rem; 
                svg, br {
                    display: none;
                }
            }
        }
        &--grey {
            background-color: #F8F8F6;
            @include gridDown(md) {
                background-color: #FFF;
            }
        }

        &--right {
            display: flex;
            align-items: flex-end;
            @include gridDown(md) {
                display: block;
                background-color: #F8F8F6 !important;
            }
        }
    }

    &__button {
        text-align: right;
        margin-top: var(--spacing-4); 
        @include gridDown(md) {
            text-align: center;
        }
    }
}

.list {
    ul {
        list-style: none;
        li {
            display: flex;
            margin-bottom: 1em; 
            &:before {
                content: "\f00c";
                font-family: "Font Awesome 5 Pro";
                font-size: 1.5em;
                margin-right: .5em;
                color: var(--color-blue);
            }
        }
    }
    
}