$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);


@mixin gridUp ( $breakpoint ) {
    @media screen and ( min-width: map-get($breakpoints, $breakpoint ) ) {
        @content;
    }
}

@mixin gridDown ( $breakpoint ) {
  @media screen and ( max-width: map-get($breakpoints, $breakpoint ) ) {
      @content;
  }
}

@mixin linkBase {
    color: var(--color-blue);
    font-weight: 700;
    text-decoration: underline;
}